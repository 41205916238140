// moment import
import moment from 'moment';
import { axiosDefaultClient } from '../settings/axios.settings';
import { getSiteUrl, retrieveDecryptedUserFromLocalStorage, storeEncryptedUserInLocalStorage } from './../utils/common';

const client = axiosDefaultClient;

function getCSRFToken() {
    return document.head.querySelector('[name=csrf-token]').content;
}

function logout() {
    return client.post('/logout').then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('language');
        localStorage.removeItem('logo');
        localStorage.removeItem('token');
        localStorage.removeItem('token_expires');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('numberOfrefresh');
    });
}

function handleResponse(response) {
    if (response.status !== 200) {
        if (response.status === 401) {
            logout();
            location.reload(true);
        }
        const error =
            (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;
}

function login(username, password, history, location) {
    let url = location;
    let _url = url;
    url = url.replace('.testing', '');
    url = url.replace('.new', '');
    url = url.replace('.develop', '');
    url = url.replace('.staging1', '');
    // if(location.includes("testing")) url = location.replace(".testing","")
    // if(location.includes("staging2")) url = location.replace(".staging2","")

    return new Promise((resolve, reject) => {
        const data = {
            username,
            password,
            site_url: getSiteUrl(),
        };

        client
            .post('/login', data)
            .then(response => {
                const responseData = handleResponse(response);

                // if(url.includes(responseData.user.site_relation.url) || responseData.user.role ==="administrator"
                // || _url.includes(responseData.user.site_relation.url))
                // {
                localStorage.setItem('logo', JSON.stringify(responseData.logo));
                storeEncryptedUserInLocalStorage(responseData.user);
                localStorage.setItem(
                    'language',
                    JSON.stringify(responseData.language),
                );
                localStorage.setItem('token', responseData.access_token);
                localStorage.setItem(
                    'refresh_token',
                    responseData.refresh_token,
                );
                // const expiry = moment();
                const expiry = responseData.expires_in;
                // expiry.add(responseData.expires_in, 'seconds');
                localStorage.setItem('token_expires', expiry);

                resolve(responseData);
                // }
                // else{
                //     reject ("Unauthorized to login")
                // }
            })
            .catch(error => {
                reject(error.data.error);
            });
    });
}

function requestPasswordReset(username, email) {
    return new Promise((resolve, reject) => {
        client
            .post('/api/auth/forget-password', {
                username,
                email,
                site_url: getSiteUrl(),
            })
            .then(response => {
                const responseData = handleResponse(response);
                resolve(responseData);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function requestPasswordResetV2(username, email, newPassword, confirmPassword, token) {
    return new Promise((resolve, reject) => {
        client
            .post('/api/auth/reset-password', {
                username,
                email,
                password: newPassword,
                password_confirmation: confirmPassword,
                token,
                site_url: getSiteUrl()
            })
            .then(response => {
                const responseData = handleResponse(response);
                resolve(responseData);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getFirstLoginData(userID) {
    return new Promise((resolve, reject) => {
        client
            .post('/api/users/get-first-login-data', {
                userID,
            })
            .then(response => {
                const responseData = handleResponse(response);
                resolve(responseData);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export const userService = {
    login,
    logout,
    requestPasswordReset,
    requestPasswordResetV2,
    getFirstLoginData,
};
