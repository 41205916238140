import React from 'react';
import { Menu, Modal } from 'antd';
import { userActions } from '../../redux/actions/user.actions';
import './Header.scss';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {utilsConstants} from "../../redux/constants/UtilsState/utils.constants";



const HeaderProfileMenu = (props) => {
    const { user, history } = props;
    const dispatch = useDispatch()

    const {logoutClicked} = useSelector(state => state.utils)

    const logout = (dispatch, logoutClicked) => {
        !logoutClicked ? userActions.logout(history) : dispatch({type: utilsConstants.LOGOUT_AFTER_REQUEST, payload: true})
    };

    return (
        <Menu
            style={{ textAlign: 'center', paddingBottom: 20 }}
            className="headerProfileMenu"
        >
            <Menu.Item
                style={{ padding: '10px 60px', backgroundColor: 'none' }}
            >
                <span style={{ fontWeight: 600 }}>{user.name}</span>
            </Menu.Item>
            <Menu.Divider />
            {(user.role === 'trainer' ? (
                <>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={() => history.push('/profile')}
                    >
                        <span>Profile</span>
                    </Menu.Item>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={() => history.push('/admin/interface-language')}
                    >
                        <span>Interface Language</span>
                    </Menu.Item>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={logout}
                    >
                        <span>Log out</span>
                    </Menu.Item>
                </>
            ) :(
                <>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={() => history.push('/profile')}
                    >
                        <span>Profile</span>
                    </Menu.Item>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={() => history.push('/admin/interface-language')}
                    >
                        <span>Interface Language</span>
                    </Menu.Item>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={() => {
                            history.push('/admin/migrate-content');
                        }}
                    >
                        <span>Settings</span>
                    </Menu.Item>
                    <Menu.Item
                        className="headerProfileMenuItems"
                        onClick={() => {
                            !logoutClicked ? logout() : dispatch({type: utilsConstants.LOGOUT_AFTER_REQUEST, payload: true})
                        }}
                    >
                        <span>Log out</span>
                    </Menu.Item>
                </>
            ))}
        </Menu>
    );
};

export default HeaderProfileMenu;
