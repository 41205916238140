import axios from 'axios';
import {axiosDefaultClient} from '../../settings/axios.settings';
import userGroupConstants from '../constants/userGroup.constants';
import {notification} from 'antd';
import {feedbackTrainerProgressConstants} from "../constants/feedbackTrainerProgress.constants";

const client = axiosDefaultClient;
const {CancelToken} = axios;
let cancelSearch;

const fetchUserGroups = (
    page = 1,
    pageSize = 10,
    filters = [],
    query = '',
    state,
    siteId,
) => {
    return dispatch => {
        dispatch({
            type: userGroupConstants.FETCH_USER_GROUPS_REQUEST,
        });
        dispatch({
            type: feedbackTrainerProgressConstants.GET_TRAINER_LEARNER_GROUP_REQUEST
        })
        let url = '';
        if (query !== '') {
            url =
                `/api/user-groups/search?page=${page?.toString()}&per_page=${pageSize}&type=${query}` +
                (state ? '&state=' + state : '');
        } else {
            url =
                `/api/user-groups?page=${page?.toString()}&per_page=${pageSize}` +
                (state ? '&state=' + state : '');
        }
        if (siteId) url = url + `&site=${siteId}`;
        if (filters.length > 0) {
            filters.forEach(filter => {
                const attrs = Object.getOwnPropertyNames(filter);
                attrs.forEach(attr => {
                    url += `&filters[]=${attr}=${filter[attr]}`;
                });
            });
        }
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userGroupConstants.FETCH_USER_GROUPS_SUCCESS,
                    payload: response.data,
                });
                dispatch({
                    type: feedbackTrainerProgressConstants.GET_TRAINER_LEARNER_GROUP_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userGroupConstants.FETCH_USER_GROUPS_REJECTED,
                    payload: error,
                });
                dispatch({
                    type: feedbackTrainerProgressConstants.GET_TRAINER_LEARNER_GROUP_REJECTED,
                    payload: error?.data?.message,
                });
            });
    };
};

const fetchAllUserGroups = (query, siteId, type, per_page = undefined) => {
    return dispatch => {
        dispatch({
            type: userGroupConstants.FETCH_USER_GROUPS_REQUEST,
        });
        let url = '/api/user-groups/search?';
        if (query) url = url + `&keyword=${query}`;
        if (siteId) url = url + `&siteID=${siteId}`;
        if (type) url = url + `&type=${type}`;
        if (per_page) url = url + `&per_page=${per_page}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userGroupConstants.FETCH_USER_GROUPS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userGroupConstants.FETCH_USER_GROUPS_REJECTED,
                    payload: error,
                });
            });
    };
};
const fetchUserGroupsList = siteID => {
    return dispatch => {
        dispatch({
            type: userGroupConstants.FETCH_USER_GROUPS_REQUEST,
        });

        let url = '';
        if (siteID) {
            url = `/api/sites/${siteID}/groups`;
        } else url = `/api/user-groups/?type=select`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userGroupConstants.FETCH_USER_GROUPS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error => {
                dispatch({
                    type: userGroupConstants.FETCH_USER_GROUPS_REJECTED,
                    payload: error,
                });
            });
    };
};

const createGroup = (data, history) => {
    return dispatch => {
        dispatch({type: userGroupConstants.SAVE_USER_GROUP_REQUEST});
        const url = `/api/user-groups`;
        client
            .post(url, data)
            .then(res => {
                dispatch({
                    type: userGroupConstants.SAVE_USER_GROUP_SUCCESS,
                });
                history.push({
                    pathname: '/admin/user-groups',
                });
                notification.success({
                    message: 'success',
                });
            })
            .catch(error => {
                dispatch({
                    type: userGroupConstants.SAVE_USER_GROUP_REJECTED,
                });
                notification.error({
                    message: error.data.message,
                });
            });
    };
};

const updateGroup = (userGroupID, data, history, pagePagination = null) => {
    return dispatch => {
        dispatch({type: userGroupConstants.SAVE_USER_GROUP_REQUEST});
        const url = `/api/user-groups/${userGroupID}`;
        client
            .post(url, data)
            .then(() => {
                dispatch({
                    type: userGroupConstants.SAVE_USER_GROUP_SUCCESS,
                });
                const historyObj = {
                    pathname: '/admin/user-groups',
                };
                if (pagePagination) {
                    historyObj.search = `?page=${pagePagination.page}&pageSize=${pagePagination.pageSize}&selectedSite=${pagePagination.selectedSite}&keyword=${pagePagination.keyword}`;
                }
                history.push(historyObj);
                notification.success({
                    message: 'success',
                });
            })
            .catch(error => {
                dispatch({
                    type: userGroupConstants.SAVE_USER_GROUP_REJECTED,
                });
                console.log(error, 'error');
                notification.error({
                    message: error.data.message,
                });
            });
    };
};

function searchUserGroups(params, nextPage, pageSize = 10, siteID, state) {
    cancelSearch && cancelSearch();
    return dispatch => {
        dispatch({type: userGroupConstants.SEARCH_USER_GROUPS_REQUEST});
        const page = nextPage || 1;
        const url =
            `/api/user-groups/search?page=${page}&per_page=${pageSize}` +
                (siteID ? '&site=' + siteID : '') +
                (state ? '&state=' + state : '')
            ;
        client
            .get(url, {
                params,
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearch = c;
                }),
            })
            .then(response => {
                dispatch({
                    type: userGroupConstants.SEARCH_USER_GROUPS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userGroupConstants.SEARCH_USER_GROUPS_REJECTED,
                    payload: error,
                });
            });
    };
}

function getUserGroup(userGroupID) {
    return dispatch => {
        dispatch({type: userGroupConstants.GET_USER_GROUP_REQUEST});
        const url = `/api/user-groups/${userGroupID}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userGroupConstants.GET_USER_GROUP_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: userGroupConstants.GET_USER_GROUP_REJECTED,
                    payload: error,
                });
            });
    };
}

function getUserGroupContent(userGroupID) {
    return dispatch => {
        dispatch({type: userGroupConstants.GET_USER_GROUP_REQUEST});
        const url = `/api/user-groups/${userGroupID}/content`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userGroupConstants.GET_USER_GROUP_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: error.message,
                });
                // dispatch({
                //     type: userGroupConstants.GET_USER_GROUP_REJECTED,
                //     payload: error,
                // });
            });
    };
}

const fetchUsersByGroup = (userGroupID, start, end) => {
    return dispatch => {
        dispatch({type: 'FETCH_USERS_BY_GROUP_REQUEST'});
        const url = `/api/user-groups/${userGroupID}/users`;
        client
            .post(url, {
                start,
                end,
            })
            .then(response => {
                dispatch({
                    type: 'FETCH_USERS_BY_GROUP_SUCCESS',
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: 'FETCH_USERS_BY_GROUP_REJECTED',
                    payload: error,
                });
            });
    };
};

const fetchReportForUsers = (
    userGroupID,
    userIDs,
    bundleFilterID,
    courseFilterID,
    pathwayFilterID,
    sectionFilterID,
    moduleFilterID,
    start,
    end,
) => {
    return dispatch => {
        dispatch({type: 'FETCH_REPORT_FOR_USERS_REQUEST'});
        const url = `/api/user-groups/${userGroupID}/users/report`;
        const data = {
            userIDs,
            courseBundleID: bundleFilterID,
            courseID: courseFilterID,
            learningPathwayID: pathwayFilterID,
            learningPathwaySectionID: sectionFilterID,
            moduleID: moduleFilterID,
            start,
            end,
        };
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: 'FETCH_REPORT_FOR_USERS_SUCCESS',
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersReportPDF = (fileName, userID, download = false) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/user-groups/download`;
        const data = {
            fileName,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {type: 'application/pdf'}),
                );
                const link = document.createElement('a');
                link.href = url;
                if (download) {
                    link.setAttribute('download', 'file.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else {
                    window.open(url);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersReportXLS = (userID, report, type) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/user-groups/export-xls`;
        const data = {
            report,
            type,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersReportCSV = (userID, report, type) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/user-groups/export-csv`;
        const data = {
            report,
            type,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'text/plain',
                    }),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const getUserGroupTrainers = (page = 1, pageSize = 10, siteID, groupId) => {
    return dispatch => {
        let url = `/api/users?page=${page}&per_page=${pageSize}&site=${siteID}&role=trainer&assignedUserGroup=${groupId}`;
        dispatch({type: userGroupConstants.FETCH_USERS_GROUPs_TRAINERS});
        client
            .get(url)
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.FETCH_USERS_GROUPs_TRAINERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const getUserGroupLearners = (page = 1, pageSize = 10, siteID, groupId) => {
    return dispatch => {
        let url = `/api/users?page=${page}&per_page=${pageSize}&site=${siteID}&role=learner&assignedUserGroup=${groupId}`;
        dispatch({type: userGroupConstants.FETCH_USERS_GROUPs_LEARNERS});
        client
            .get(url)
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.FETCH_USERS_GROUPs_LEARNERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const getUserGroupHrManager = (page = 1, pageSize = 10, siteID, groupId) => {
    return dispatch => {
        let url = `/api/users?page=${page}&per_page=${pageSize}&site=${siteID}&role=hrmanager&assignedUserGroup=${groupId}`;
        dispatch({type: userGroupConstants.FETCH_USERS_GROUPs_HR_MANAGERS});
        client
            .get(url)
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.FETCH_USERS_GROUPs_HR_MANAGERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const addTrainersToGroup = (users, groupId) => {
    return dispatch => {
        let url = `/api/user-groups/${groupId}/bulk/assign-trainers`;
        dispatch({type: userGroupConstants.Add_TRAINERS_TO_USERS_GROUPS});
        let userIDs = users.map(user => user.userID);
        client
            .post(url, {userIDs, groupId})
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.Add_TRAINERS_TO_USERS_GROUPs_SUCCESS,
                    payload: users,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const removeTrainersFromGroup = (users, groupId) => {
    return dispatch => {
        let url = `/api/user-groups/${groupId}/bulk/revoke-trainers`;
        let userIDs = users.map(user => user.userID);
        client
            .post(url, {userIDs})
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.Remove_TRAINERS_FROM_USERS_GROUPs_SUCCESS,
                    payload: users,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const addLearnersToGroup = (users, groupId) => {
    return dispatch => {
        let url = `/api/user-groups/${groupId}/bulk/assign-learners`;
        dispatch({type: userGroupConstants.Add_LEARNERS_TO_USERS_GROUPS});
        let userIDs = users.map(user => user.userID);
        client
            .post(url, {userIDs, groupId})
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.Add_LEARNERS_TO_USERS_GROUPS_SUCCESS,
                    payload: users,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const removeLeanerFromGroup = (users, groupId) => {
    return dispatch => {
        let url = `/api/user-groups/${groupId}/bulk/revoke-learners`;
        let userIDs = users.map(user => user.userID);
        client
            .post(url, {userIDs})
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.Remove_LEARNERS_FROM_USERS_GROUPs_SUCCESS,
                    payload: users,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const removeHrFromGroup = (users, groupId) => {
    return dispatch => {
        let url = `/api/user-groups/${groupId}/bulk/revoke-hrmanagers`;
        let userIDs = users.map(user => user.userID);
        client
            .post(url, {userIDs})
            .then(response => {
                dispatch({
                    type:
                    userGroupConstants.Remove_HR_FROM_USERS_GROUPs_SUCCESS,
                    payload: users,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const addHrManagerToGroup = (users, groupId) => {
    return dispatch => {
        let url = `/api/user-groups/${groupId}/bulk/assign-hrmanagers`;
        dispatch({type: userGroupConstants.Add_HR_MANAGER_TO_GROUPS});
        let userIDs = users.map(user => user.userID);
        client
            .post(url, {userIDs, groupId})
            .then(response => {
                dispatch({
                    type: userGroupConstants.Add_HR_MANAGER_TO_GROUPS_SUCCESS,
                    payload: users,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const userGroupActions = {
    getUserGroupContent,
    removeLeanerFromGroup,
    removeHrFromGroup,
    removeTrainersFromGroup,
    addHrManagerToGroup,
    addLearnersToGroup,
    addTrainersToGroup,
    getUserGroupHrManager,
    getUserGroupLearners,
    getUserGroupTrainers,
    fetchAllUserGroups,
    fetchUserGroups,
    createGroup,
    updateGroup,
    searchUserGroups,
    getUserGroup,
    fetchUsersByGroup,
    fetchReportForUsers,
    downloadUsersReportPDF,
    downloadUsersReportCSV,
    downloadUsersReportXLS,
    fetchUserGroupsList,
};

export default userGroupActions;
