const learningPathwayConstants = {
    FETCH_LEARNING_PATHWAYS_REQUEST: 'FETCH_LEARNING_PATHWAYS_REQUEST',
    FETCH_LEARNING_PATHWAYS_SUCCESS: 'FETCH_LEARNING_PATHWAYS_SUCCESS',
    FETCH_LEARNING_PATHWAYS_REJECTED: 'FETCH_LEARNING_PATHWAYS_REJECTED',
    FETCH_OTHER_TRAINER_PATHWAYS_REQUEST: 'FETCH_OTHER_TRAINER_PATHWAYS_REQUEST',
    FETCH_OTHER_TRAINER_PATHWAYS_SUCCESS: 'FETCH_OTHER_TRAINER_PATHWAYS_SUCCESS',
    FETCH_OTHER_TRAINER_PATHWAYS_REJECTED: 'FETCH_OTHER_TRAINER_PATHWAYS_REJECTED',
    FETCH_LEARNING_PATHWAY_REQUEST: 'FETCH_LEARNING_PATHWAY_REQUEST',
    FETCH_LEARNING_PATHWAY_SUCCESS: 'FETCH_LEARNING_PATHWAY_SUCCESS',
    FETCH_LEARNING_PATHWAY_REJECTED: 'FETCH_LEARNING_PATHWAY_REJECTED',
    RESET_LEARNING_PATHWAY: 'RESET_LEARNING_PATHWAY',
    SAVE_LEARNING_PATHWAY_REQUEST: 'SAVE_LEARNING_PATHWAY_REQUEST',
    SAVE_LEARNING_PATHWAY_SUCCESS: 'SAVE_LEARNING_PATHWAY_SUCCESS',
    SAVE_LEARNING_PATHWAY_REJECTED: 'SAVE_LEARNING_PATHWAY_REJECTED',
    SEARCH_LEARNING_PATHWAY_REQUEST: 'SEARCH_LEARNING_PATHWAY_REQUEST',
    SEARCH_LEARNING_PATHWAY_SUCCESS: 'SEARCH_LEARNING_PATHWAY_SUCCESS',
    SEARCH_LEARNING_PATHWAY_REJECTED: 'SEARCH_LEARNING_PATHWAY_REJECTED',
    SWITCH_LEARNING_PATHWAY_REQUEST: 'SWITCH_LEARNING_PATHWAY_REQUEST',
    SWITCH_LEARNING_PATHWAY_SUCCESS: 'SWITCH_LEARNING_PATHWAY_SUCCESS',
    SWITCH_LEARNING_PATHWAY_REJECTED: 'SWITCH_LEARNING_PATHWAY_REJECTED',
    SAVE_EXTRA_CONTENT_REQUEST: 'SAVE_EXTRA_CONTENT_REQUEST',
    SAVE_EXTRA_CONTENT_SUCCESS: 'SAVE_EXTRA_CONTENT_SUCCESS',
    SAVE_EXTRA_CONTENT_REJECTED: 'SAVE_EXTRA_CONTENT_REJECTED',
    SAVE_CANCELLED: 'SAVE_CANCELLED',
    FETCH_LEARNER_PATHWAYS_REQUEST: 'FETCH_LEARNER_PATHWAYS_REQUEST',
    FETCH_LEARNER_PATHWAYS_SUCCESS: 'FETCH_LEARNER_PATHWAYS_SUCCESS',
    FETCH_LEARNER_PATHWAYS_REJECTED: 'FETCH_LEARNER_PATHWAYS_REJECTED',
    CLEARDATA: 'CLEARDATA',
    CLEAR_PATHWAY: 'CLEAR_PATHWAY',
    FETCH_LEARNING_PATHWAY_BY_ID_REQUEST:
        'FETCH_LEARNING_PATHWAY_BY_ID_REQUEST',
    UPDATE_CREATED_STATUS: 'UPDATED_CREATED_STATUS',
    CLEAR_CREATED_STATUS: 'CLEAR_CREATED_STATUS',
    FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_REQUEST:
        'FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_REQUEST',
    FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_SUCCESS:
        'FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_SUCCESS',
    FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_REJECTED:
        'FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_REJECTED',
    ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_REQUEST:
        'ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_REQUEST',
    ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_SUCCESS:
        'ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_SUCCESS',
    ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_REJECTED:
        'ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_REJECTED',
};

export default learningPathwayConstants;
