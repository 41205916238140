import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Modal } from 'antd';
const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <Modal
            cancelButtonProps={{ style: { display: 'none' } }}
            open={true}
            okText={window.__lang('refresh')}
            onOk={resetErrorBoundary}
        >
            <div role="alert">
                <h1>Oops! Something went wrong.</h1>
                <p>
                    We&apos;re sorry for the inconvenience. Please try
                    refreshing the page or come back later.
                </p>
            </div>
        </Modal>
    );
};

const MyErrorBoundary = ({ history, children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                let numberOfrefresh = JSON.parse(
                    localStorage.getItem('numberOfrefresh') || '{}'
                );
                let numberOfrefreshPerPath = 0;
                if (
                    numberOfrefresh &&
                    numberOfrefresh[window.location.pathname]
                ) {
                    numberOfrefreshPerPath =
                        numberOfrefresh[window.location.pathname];
                }
                if (numberOfrefreshPerPath < 2) {
                    numberOfrefreshPerPath++;
                    numberOfrefresh[window.location.pathname] =
                        numberOfrefreshPerPath;
                    localStorage.setItem(
                        'numberOfrefresh',
                        JSON.stringify(numberOfrefresh),
                    );
                    window.location.reload();
                } else {
                    localStorage.removeItem('numberOfrefresh');
                    history.push('/login');
                    window.location.reload();
                }
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default MyErrorBoundary;
